<script>
export default {
  name: 'Action',
  components: {
    Icon: () => import('@/components/general/Icon')
  },

  props: {
    type: {
      type: String,
      default: null
    },

    url: {
      type: [
        String,
        Object
      ],

      default: '#'
    },

    text: {
      type: String,
      default: null
    },

    filename: {
      type: String,
      default: null
    },

    /* external: {
      type: Boolean,
      default: false
    }, */

    tiny: {
      type: Boolean,
      default: false
    },

    target: {
      type: String,
      default: null
    },

    /* download: {
      type: Boolean,
      default: false
    }, */

    disabled: {
      type: Boolean,
      default: false
    },

    icon: {
      type: String,
      default: null
    },

    iconRight: {
      type: Boolean,
      default: false
    },

    iconSize: {
      type: String,
      default: null
    },

    flat: {
      type: Boolean,
      default: false
    },

    large: {
      type: Boolean,
      default: false
    },

    submit: {
      type: Boolean,
      default: false
    },

    primary: {
      type: Boolean,
      default: false
    },

    secondary: {
      type: Boolean,
      default: false
    },

    borderless: {
      type: Boolean,
      default: false
    },

    fixedWidth: {
      type: Boolean,
      default: false
    },

    notifyNumber: {
      type: Number,
      default: null
    },

    dark: {
      type: Boolean,
      default: false
    },

    fab: {
      type: Boolean,
      default: false
    },

    flatten: {
      type: Boolean,
      default: false
    },

    typePosition: {
      type: String,
      default: null
    },

    hideLabel: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    triggerClickEvent (event) {
      this.type !== 'button' && this.url === '#' && event.preventDefault()
      this.$emit('click', event)
      this.$refs.elem.blur()
    },

    checkTypeButton () {
      return this.submit ? 'submit' : 'button'
    },

    getTypeClass () {
      return this.primary
        ? 'btn-primary'
        : this.secondary
          ? 'btn-secondary'
          : this.borderless
            ? 'btn-borderless'
            : this.flat
              ? 'btn-flat'
              : !this.text && this.icon
                ? 'btn-icon'
                : ''
    },

    getSizeClass () {
      return this.$media.mobile && this.large ? 'is-large btn-mobile' : this.large ? 'is-large' : ''
    },

    getUrl () {
      return typeof this.url === 'object' ? this.$router.resolve(this.url).href : this.url
    }
  }
}
</script>

<template>
  <button
    v-if="type == 'button'"
    ref="elem"
    class="btn"
    :type="checkTypeButton()"
    :class="[
      {
        'is-fab': fab,
        'is-disabled': disabled,
        'is-submit': submit,
        'has-fixed-width': fixedWidth,
        'theme-dark': dark,
        'is-absolute': typePosition === 'is-absolute',
        'flat-clear': flatten,
        'is-tiny': tiny
      },
      getTypeClass(),
      getSizeClass()
    ]"
    :disabled="disabled"
    @click="triggerClickEvent"
  >
    <Icon
      v-if="icon && !iconRight"
      :name="icon"
      wrapper
      :size="iconSize"
    />

    <span
      v-if="text && hideLabel"
      class="text"
    >{{ text }}</span>

    <Icon
      v-if="icon && iconRight"
      :name="icon"
      wrapper
      :size="iconSize"
    />

    <span
      v-if="notifyNumber"
      class="number"
      :class="{'is-larger': notifyNumber > 99}"
    >{{ notifyNumber }}</span>
  </button>

  <a
    v-else-if="type == 'link'"
    ref="elem"
    class="btn"
    :class="[
      {
        'is-fab': fab,
        'is-disabled': disabled,
        'has-fixed-width': fixedWidth,
        'theme-dark': dark,
        'flat-clear': flatten
      },
      getTypeClass(),
      getSizeClass()
    ]"
    :href="getUrl()"
    :target="target"
    :download="filename"
    :disabled="disabled"
    @click="triggerClickEvent"
  >
    <Icon
      v-if="icon && !iconRight"
      :name="icon"
      wrapper
      :size="iconSize"
    />

    <span
      v-if="text"
      class="text"
    >{{ text }}</span>

    <Icon
      v-if="icon && iconRight"
      :name="icon"
      wrapper
      :size="iconSize"
    />

    <span
      v-if="notifyNumber"
      class="number"
      :class="{'is-larger': notifyNumber > 99}"
    >{{ notifyNumber }}</span>
  </a>

  <RouterLink
    v-else
    class="btn"
    :class="[
      {
        'is-fab': fab,
        'is-disabled': disabled,
        'has-fixed-width': fixedWidth,
        'theme-dark': dark
      },
      getTypeClass(),
      getSizeClass()
    ]"
    :to="getUrl()"
    :disabled="disabled"
    @click="triggerClickEvent"
  >
    <template v-if="!fab">
      <Icon
        v-if="icon && !iconRight"
        :name="icon"
        wrapper
        :size="iconSize"
      />

      <span
        v-if="text"
        class="text"
      >{{ text }}</span>

      <Icon
        v-if="icon && iconRight"
        :name="icon"
        wrapper
        :size="iconSize"
      />

      <span
        v-if="notifyNumber"
        class="number"
        :class="{'is-larger': notifyNumber > 99}"
      >{{ notifyNumber }}</span>
    </template>

    <template v-else>
      <Icon
        name="add"
        wrapper
      />
    </template>
  </RouterLink>
</template>

<style src="@/assets/styles/themes/default/action.css"></style>
