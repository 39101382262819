var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.type == 'button')?_c('button',{ref:"elem",staticClass:"btn",class:[
    {
      'is-fab': _vm.fab,
      'is-disabled': _vm.disabled,
      'is-submit': _vm.submit,
      'has-fixed-width': _vm.fixedWidth,
      'theme-dark': _vm.dark,
      'is-absolute': _vm.typePosition === 'is-absolute',
      'flat-clear': _vm.flatten,
      'is-tiny': _vm.tiny
    },
    _vm.getTypeClass(),
    _vm.getSizeClass()
  ],attrs:{"type":_vm.checkTypeButton(),"disabled":_vm.disabled},on:{"click":_vm.triggerClickEvent}},[(_vm.icon && !_vm.iconRight)?_c('Icon',{attrs:{"name":_vm.icon,"wrapper":"","size":_vm.iconSize}}):_vm._e(),(_vm.text && _vm.hideLabel)?_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.text))]):_vm._e(),(_vm.icon && _vm.iconRight)?_c('Icon',{attrs:{"name":_vm.icon,"wrapper":"","size":_vm.iconSize}}):_vm._e(),(_vm.notifyNumber)?_c('span',{staticClass:"number",class:{'is-larger': _vm.notifyNumber > 99}},[_vm._v(_vm._s(_vm.notifyNumber))]):_vm._e()],1):(_vm.type == 'link')?_c('a',{ref:"elem",staticClass:"btn",class:[
    {
      'is-fab': _vm.fab,
      'is-disabled': _vm.disabled,
      'has-fixed-width': _vm.fixedWidth,
      'theme-dark': _vm.dark,
      'flat-clear': _vm.flatten
    },
    _vm.getTypeClass(),
    _vm.getSizeClass()
  ],attrs:{"href":_vm.getUrl(),"target":_vm.target,"download":_vm.filename,"disabled":_vm.disabled},on:{"click":_vm.triggerClickEvent}},[(_vm.icon && !_vm.iconRight)?_c('Icon',{attrs:{"name":_vm.icon,"wrapper":"","size":_vm.iconSize}}):_vm._e(),(_vm.text)?_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.text))]):_vm._e(),(_vm.icon && _vm.iconRight)?_c('Icon',{attrs:{"name":_vm.icon,"wrapper":"","size":_vm.iconSize}}):_vm._e(),(_vm.notifyNumber)?_c('span',{staticClass:"number",class:{'is-larger': _vm.notifyNumber > 99}},[_vm._v(_vm._s(_vm.notifyNumber))]):_vm._e()],1):_c('RouterLink',{staticClass:"btn",class:[
    {
      'is-fab': _vm.fab,
      'is-disabled': _vm.disabled,
      'has-fixed-width': _vm.fixedWidth,
      'theme-dark': _vm.dark
    },
    _vm.getTypeClass(),
    _vm.getSizeClass()
  ],attrs:{"to":_vm.getUrl(),"disabled":_vm.disabled},on:{"click":_vm.triggerClickEvent}},[(!_vm.fab)?[(_vm.icon && !_vm.iconRight)?_c('Icon',{attrs:{"name":_vm.icon,"wrapper":"","size":_vm.iconSize}}):_vm._e(),(_vm.text)?_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.text))]):_vm._e(),(_vm.icon && _vm.iconRight)?_c('Icon',{attrs:{"name":_vm.icon,"wrapper":"","size":_vm.iconSize}}):_vm._e(),(_vm.notifyNumber)?_c('span',{staticClass:"number",class:{'is-larger': _vm.notifyNumber > 99}},[_vm._v(_vm._s(_vm.notifyNumber))]):_vm._e()]:[_c('Icon',{attrs:{"name":"add","wrapper":""}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }